import React from 'react';
import App from 'next/app';
import { DFPSlotsProvider } from 'react-dfp';
import { ApolloProvider } from '@apollo/client';
import { ResponsiveImageProvider } from '@blaze-cms/image-cdn-react';
import { withBlaze } from '@blaze-cms/nextjs-tools';
import buildConfig from '../blaze.config';
import IndexPage from '../components/IndexPage';
import { RESPONSIVE_IMAGE_CONFIG } from '../constants';

import '../styles/sass/_fonts.scss';
import '../styles/tailwind-base.css';
import '../styles/sass/main.scss';
import '../styles/globals.css';

class MyApp extends App {
  render() {
    const { Component, pageProps, apolloClient, router } = this.props;

    return (
      <ApolloProvider client={apolloClient}>
        <DFPSlotsProvider dfpNetworkId={process.env.DFP_NETWORK_ID} lazyLoad collapseEmptyDivs>
          <ResponsiveImageProvider config={RESPONSIVE_IMAGE_CONFIG}>
            <IndexPage />
            <div className="site-container">
              <Component {...pageProps} router={router} />
            </div>
          </ResponsiveImageProvider>
        </DFPSlotsProvider>
      </ApolloProvider>
    );
  }
}

export default withBlaze(MyApp, buildConfig);
